import React from 'react';
import { graphql } from 'gatsby';
import { css } from '@emotion/react';

import { SEO, Layout, Galleries, Container } from '@/components';
import PrismicComponent from '@/prismic/prismicComponent';

import { PageContext } from '@/domain/pageContext';
import { PRealisations } from '@/domain/Realisations';

interface Props {
    data: {
        prismicRealisationspage: PRealisations;
    }
    pageContext: PageContext;
}


const Realisations = ({ pageContext, data: { prismicRealisationspage } }: Props): JSX.Element => {

    const { dataHeader, alternate } = pageContext;
    const { uid, lang, alternate_languages } = prismicRealisationspage;
    const {
        seo_url,
        seo_title,
        seo_description,
        seo_keywords,
        page_content
    } = prismicRealisationspage.data;

    const selectLang = {
        lang: lang,
        uid: uid,
        alternate_languages: alternate_languages
    };


    const seo = {
        lang: lang.split('-')[0],
        url: `${lang.split('-')[0]}/${seo_url.text}`,
        title: seo_title.text,
        description: seo_description.text,
        keywords: seo_keywords.text
    }

    const linkAlternate = alternate?.map(data => ({
      lang: data.lang,
      url: `${data.lang.split('-')[0]}/${data.uid}`
    }))

    return (
        <Layout header={dataHeader} selectLang={selectLang} >
            <SEO lang={seo.lang} title={`Global Electrics | ${seo.title}`} url={seo.url} description={seo.description} keywords={seo.keywords} alternate={linkAlternate} />
            <Container css={css`padding: 2rem 0; h1{
                text-align: center;
            }`}>
                <PrismicComponent content={page_content.raw} />
            </Container>
            <Galleries lang={lang} />
        </Layout>
    );
};

export const query = graphql`
query ($id: String!) {
  prismicRealisationspage(id: {eq: $id}) {
    uid
    lang
    alternate_languages {
      lang
      uid
    }
    data {
      seo_url {
        text
      }
      seo_title {
        text
      }
      seo_description {
        text
      }
      seo_keywords {
        text
      }
      page_content {
        raw
      }
    }
  }
}
`

export default Realisations;
